<template>
  <div style=";margin: -15px -15px;background-color: #f0f2f5;">
    <!-- 类型筛选框 -->
    <div class="sift">
      <!-- 类型框 -->
      <div>
        类型: &nbsp;
        <!-- @change="searchOk" -->
        <a-radio-group  v-model="Ftype" style="margin-right: 40px" @change="searchOk">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="缺陷">
            缺陷
          </a-radio-button>
          <a-radio-button value="需求">
            需求
          </a-radio-button>
          <a-radio-button value="优化">
            优化
          </a-radio-button>
        </a-radio-group>
      </div>
      <!-- 紧急程度框 -->
      <div>
         紧急程度: &nbsp;
         <!-- v-model="searchType" -->
         <a-select placeholder="请选择紧急程度"  style="width:150px" @change="typeChange">
          <a-select-option v-for="type in stateTypeList" :value="type.state">
            {{ type.title }}
          </a-select-option>
         </a-select>
      </div>

      <!-- 终端框 -->
      <div>
         终端: &nbsp;
         <a-select placeholder="请选择终端"  style="width:150px" @change="type2Change">
          <a-select-option v-for="type in state2TypeList" :value="type.state">
            {{ type.title }}
          </a-select-option>
         </a-select>
      </div>
      <!-- 问题状态框 -->
      <div>
         问题状态: &nbsp;
         <a-select placeholder="请选择状态"  style="width:124px" @change="type3Change">
           <a-select-option  value="">
             全部
           </a-select-option>
           <a-select-option  value="待安排">
             待安排
           </a-select-option>
           <a-select-option  value="已安排">
             已安排
           </a-select-option>
           <a-select-option  value="待确认">
             待确认
           </a-select-option>
           <a-select-option  value="未解决">
             未解决
           </a-select-option>
           <a-select-option  value="已解决">
             已解决 
           </a-select-option>
           <a-select-option  value="已关闭">
             已关闭
           </a-select-option>
           <a-select-option  value="暂不处理">
             暂不处理
           </a-select-option>
         </a-select>
      </div>
    </div>
    <!-- table框 -->
    <div style="background-color: #fff;margin-top: 20px; padding: 0 18px;">
      <!-- 搜索框 -->
      <div style="margin-bottom: 20px;padding-top: 10px; display: flex;">
        <a-button type="primary" style="margin-bottom: 5px;margin-right:3%;" @click="newlyBuilt">
          <a-icon type="plus" />
          新建
        </a-button>
        <a-input style="width: 200px;margin-right:1%;" class="input" placeholder="请输入问题描述" v-model="searchName">
        </a-input>
        <a-button type="primary" style="margin-bottom: 5px;" @click="search">
          <a-icon type="search"/>
          搜索
        </a-button>
          <a-tree
             v-model="checkedKeys"
             checkable
             :expanded-keys="expandedKeys"
             :auto-expand-parent="autoExpandParent"
             :selected-keys="selectedKeys"
             :tree-data="treeData"
           />
      </div>
      <!-- 新建的抽屉 -->
      <a-drawer title="新增/编辑" placement="right" :closable="false" :visible="newlyVisible"
          :after-visible-change="afterVisibleChange" @close="onClose1" width="50%">
          <template>
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="form">
              <a-form-model-item  label="描述" prop="content" >
                <a-textarea v-model="form.content" auto-size placeholder="请输入描述" style="min-height: 150px;font-size: 16px"/>
              </a-form-model-item>
              <a-form-model-item label="紧急程度"  prop="degree">
                <a-select v-model="form.degree" placeholder="请选择">
                  <a-select-option value="紧急">
                    紧急
                  </a-select-option>
                  <a-select-option value="不紧急">
                    不紧急
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="所属模块"  prop="module">
                <a-input v-model="form.module" placeholder="请输入" />
                <!-- <a-select v-model="form.module" placeholder="请选择">
                  <a-select-option value="点播">
                    点播
                  </a-select-option>
                  <a-select-option value="视频库管理">
                    视频库管理
                  </a-select-option>
                  <a-select-option value="直播">
                    直播
                  </a-select-option>
                  <a-select-option value="社区">
                    社区
                  </a-select-option>
                </a-select> -->
              </a-form-model-item>
              <a-form-model-item label="所属终端"  prop="channel">
                <a-select v-model="form.channel" placeholder="请选择">
                  <a-select-option value="app">
                    APP
                  </a-select-option>
                  <a-select-option value="管理后台">
                    管理后台
                  </a-select-option>
                  <a-select-option value="官网">
                    官网
                  </a-select-option>
                  <a-select-option value="小程序">
                    小程序
                  </a-select-option>
                  <a-select-option value="公众号">
                    公众号
                  </a-select-option>
                  <a-select-option value="电脑客户端">
                    电脑客户端
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item label="上传图片" prop="images" >
                <div>
                  <div>
                    <span class="uploadImg">
                      <input
                          id="inputTest"
                          ref="addImg"
                          accept="image/*"
                          style="width: 100%;height:100%;opacity: 0;margin-right: 14px;"
                          type="file"
                          @change="uploadImg('addImg')"
                          />
                    </span>
                    <span id="yulan">
                      <span class="upload_gutter" v-for="(items,index) in form.images" >
                        <div style="height: 120px;width: 120px;position: relative;margin-right: 10px;border: #DDDDDD 2px dashed;">
                          <a-icon type="close" style="color: red;position: absolute;right: 0;" @click="getIndex(index)"/> 
                          <img :index="index"
                               :src="items"
                               alt=""  
                               style="width: 120px;height: 120px;"
                               @click="openVideoUrl(items)"
                          />
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </a-form-model-item>
              <a-form-model-item style="margin-top: -20px;margin-left: 97px;"> 
                <div style="font-size: xx-small;color: #a1a1a1;">支持扩展名：.jpg .png .jpeg ... <br> 注：最多只能上传9张图片</div>
                <!-- <div style="font-size: xx-small;color: #a1a1a1;">注：最多只能上传9张图片</div> -->
              </a-form-model-item>
              <a-form-model-item prop="videos" label="视频">
                <div>
                  <div style="width: 100%;">
                    <!-- 视频预览框 -->
                    <span style="float:left;width:100%"> 
                      <span class="uploadImgVideo" >
                        <input
                          :disabled="disabledVideo"
                          id="up_video_surface_plot"
                          style="width: 120px;height:100%;opacity: 0;float:left"
                          type="file" accept=".mp4"
                          @change="content_surface_plot_original_video($event)" />
                      </span>


                      <div>
                        <span v-for="(items,index) in form.videos">
                          <div
                          class="upload_LiveBroadcast">
                          <video
                              @click="openVideoUrl(items)"
                              style="width: 120px;height:120px;float:right"
                              :src="items"></video>
                          <a-icon type="close" style="color: red;float: right;transform: translateY(-120px);" @click="getVideoIndex(index)"/> 
                          </div>
                          </span>
                      </div>
                    </span>
                  </div>
                  <div v-if="disabledVideo" style="color: #ff0000">视频上传中...</div>
                </div>
              </a-form-model-item>
              <a-form-model-item style="margin-top: -20px;margin-left: 97px;">
                <div style="color: #a1a1a1;float:left;font-size: xx-small;">注：视频格式为mp4格式，请等视频上传成功再提交</div>
              </a-form-model-item>
              <a-form-model-item  label="备注">
                <a-textarea v-model="form.remark" auto-size placeholder="请输入手机/电脑型号、系统版本号或其他内容" style="min-height: 120px;font-size: 16px"/>
                <span style="float:right;color: #a1a1a1;" v-if="this.buttonType === true"> 创建时间：{{ this.startTime }} </span>
              </a-form-model-item>
            </a-form-model>
             <div
               :style="{
                 position: 'absolute',
                 right: 0,
                 bottom: 0,
                 width: '100%',
                 borderTop: '1px solid #e9e9e9',
                 padding: '10px 16px',
                 background: '#fff',
                 textAlign: 'right',
                 zIndex: 1,
               }"
             >
               <a-button :style="{ marginRight: '8px' }" @click="()=>{
                 this.newlyVisible = false;
                 this.$refs.form.resetFields()
               }">
                 取消
               </a-button>
               <!-- 提交并更新简介 -->
               <a-button :style="{ marginRight: '8px' }" type="primary" @click="submit" v-if="buttonType === true" >
                 确认
               </a-button>
               <a-button :style="{ marginRight: '8px' }" type="primary" @click="editData" v-if="buttonType === false">
                 确定
               </a-button>
             </div>
          </template>
      </a-drawer>
      <!-- 详情table框 -->
      <div>
        <a-table :columns="columns" :data-source="tableData" :scroll="{ x: 2200}" :pagination="false" :row-key="(record) => record.id">
          <!-- tag标签 -->
          <span slot="titles" slot-scope="text,item">
            <a-tag :color="item.type === '需求' ? 'green' : item.type === '优化' ? 'orange' : item.type === '缺陷' ? 'red' : 'blue'" :key="item.type">
              {{ item.type }}
            </a-tag>
          </span>
          <span slot="degree" slot-scope="text,item" style="display: flex;align-items:center">
            <div style="background-color: red;border-radius:50%;width:8px;height: 8px; margin-right:4px" v-if="item.degree === '紧急'"></div>
            <div style="background-color: #2ff257;border-radius:50%;width:8px;height: 8px; margin-right:4px" v-if="item.degree === '不紧急'"></div>
            {{ item.degree }}
          </span>
          <!-- 描述详情 -->
          <span slot="details" slot-scope="text,item">
            <!-- 悬浮框 -->
            <a-tooltip placement="topLeft">
              <template slot="title">
                {{ item.content }}
              </template>
              <a @click="contentDetails(item)" class="multi-ellipsis">{{ item.content }}</a>
            </a-tooltip>
          </span>
          <!-- 操作 -->
          <a slot="action" slot-scope="text,item">
            <!-- <a  style="margin-right: 5px"   @click="ArrangeBtn(item)">安排</a> -->
            <a  style="margin-right: 8px"  v-if="item.status==='待安排'" @click="ArrangeBtn(item)">安排</a>

            <a style="margin-right: 8px"  v-if="item.status==='已安排'" @click="Resolved(item)">解决方案</a>
            
            <a-popconfirm title="确认已解决吗？" ok-text="是" cancel-text="否" @confirm="ResolvedStatus(item)"> 
              <a  style="margin-right: 8px"  v-if="item.status==='待确认' || item.status==='未解决' " >已解决</a>
            </a-popconfirm>

            <!-- @confirm="confirm3Del(record.id) -->
            <a-popconfirm title="确认该问题未解决吗？" ok-text="是" cancel-text="否" @confirm="confirm(item.id)">             
              <a style="margin-right: 8px"  v-if="item.status==='待确认'">未解决</a>
            </a-popconfirm>

            <a-popconfirm title="确认重新打开吗？" ok-text="是" cancel-text="否" @confirm="reConfirm(item.id)">  
              <a style="margin-right: 8px"  v-if="item.status==='已解决'">重新打开</a>
            </a-popconfirm>

            <a-popconfirm title="确认重新打开吗？" ok-text="是" cancel-text="否" @confirm="resetFeekBack(item.id)">  
              <a style="margin-right: 8px;"  v-if="item.status==='暂不处理'">重新打开</a>
            </a-popconfirm>

            <a  v-if="item.status==='待安排'" @click="FeekBackChange(item)" style="margin-right: 8px">修改</a>

            <a-popconfirm title="确认关闭吗？" ok-text="是" cancel-text="否" @confirm="closeFeekBack(item.id)">  
              <a  v-if="item.status==='待安排'" style="margin-right: 8px">关闭</a>
            </a-popconfirm>

            <a-popconfirm title="确认暂不处理吗？" ok-text="是" cancel-text="否" @confirm="NotHandledFeekBack(item.id)">  
              <a  v-if="item.status==='待安排'" style="margin-right: 8px">暂不处理</a>
            </a-popconfirm>

            <a-popconfirm title="确认删除吗？" ok-text="是" cancel-text="否" @confirm="deleteFeekBack(item.id)">  
              <a style="color:red" v-if="item.status==='待安排'">删除</a>
            </a-popconfirm>

            <a-popconfirm title="确认重启吗？" ok-text="是" cancel-text="否" @confirm="resetFeekBack(item.id)">  
              <a v-if="item.status ==='已关闭'">重启</a>
            </a-popconfirm>
           
          </a>
        </a-table>
        <!-- 描述详情的抽屉 -->
        <a-drawer title="详情" placement="right" :closable="false" :visible="addVisible"
          :after-visible-change="afterVisibleChange" @close="onClose" width="70%">
          <template>
            <a-descriptions title="基础信息" style="border-bottom: 1px solid #e8e8e8;">
              <a-descriptions-item label="紧急程度">
                {{ this.contentData.degree }}
              </a-descriptions-item>
              <a-descriptions-item label="所属模块">
                {{ this.contentData.module }}
              </a-descriptions-item>
              <a-descriptions-item label="类型">
                {{ this.contentData.type }}
              </a-descriptions-item>
              <a-descriptions-item label="所属终端">
                {{ this.contentData.channel }}
              </a-descriptions-item>
              <a-descriptions-item label="创建人">
                {{ this.contentData.name }}
              </a-descriptions-item>
              <a-descriptions-item label="创建时间">
                {{this.contentData.createdTime}} 
              </a-descriptions-item>
              <a-descriptions-item label="计划完成日期">
                {{this.contentData.plannedDate}}
              </a-descriptions-item><br><br>
              <a-descriptions-item label="问题描述" span="3">
                {{ this.contentData.content  }}
              </a-descriptions-item>
              <a-descriptions-item label="备注" span="3">
                {{ this.contentData.remark  }}
              </a-descriptions-item>
              <div style="margin-bottom: 100px;"></div>
              <div style="margin-bottom: 100px;"></div>
            </a-descriptions>
            <!-- 图片视频框 -->
            <div class="imgBox">
              <h3 style="margin-bottom: 20px;margin-top: 30px;color: rgba(0, 0, 0, 0.85);font-weight: bold;font-size: 16px;">附加信息</h3>
              <div>
                <h4 v-if="this.IMAGES.length !== 0">图片：</h4>
                <div style="margin-bottom: 20px;">
                  <span v-for="item in IMAGES" style="margin-right: 20px;">
                    <img 
                     @click="openVideoUrl(item)"
                   :src="item" alt="" 
                     style="display: inline-block;border: #DDDDDD 1px solid;width: 17%;height: 150px;">
                  </span>
                </div>
                <h4 v-if="this.IMAGES.length !== 0">视频：</h4>
                <div style="margin-bottom: 70px;">
                  <span v-for="vitem in VIEDOS" style="margin-right: 20px;">
                    <video controls width="250" style="display: inline-block;">
                    <source :src="vitem" type="video/mp4" />
                    </video>
                  </span>
                </div>

              </div>
            </div>
            <!-- 解决方案的弹窗 -->
            <div>
               <h3 style="margin-bottom: 20px;margin-top: 30px;color: rgba(0, 0, 0, 0.85);font-weight: bold;font-size: 16px;">解决方案</h3>
               <a-table :columns="columns1" :data-source="detailTableData" :scroll="{ y: 300 }" :pagination="false" :row-key="(record) => record.id">
                 <!-- 操作 -->
                 <a slot="action" slot-scope="text,item"  @click="revise(item)">修改</a>
               </a-table>

               <!-- 修改解决方案的弹窗 -->
               <a-modal v-model="reviseShow" title="解决方案" @cancel="handleCancel">
                 <a-form-model :model="reviseForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="reviseFormRules" ref="reviseForm">
                   <a-form-model-item label="处理人 " prop="name">
                     <a-input v-model="reviseForm.name" placeholder="请输入" />
                   </a-form-model-item>
                   <a-form-model-item  label="处理日期" prop="time">
                     <a-date-picker
                         v-model="reviseForm.time"
                         style="width: 100%"
                         format="YYYY-MM-DD"
                     />
                   </a-form-model-item>
                   <a-form-model-item  label="方案描述" prop="content" >
                     <a-textarea v-model="reviseForm.content" auto-size placeholder="请输入方案描述" style="min-height: 150px;font-size: 16px"/>
                   </a-form-model-item>
                 </a-form-model>
                 <div
                   :style="{
                     position: 'absolute',
                     right: 0,
                     bottom: 0,
                     width: '100%',
                     borderTop: '1px solid #e9e9e9',
                     padding: '10px 16px',
                     background: '#fff',
                     textAlign: 'right',
                     zIndex: 1,
                   }"
                 >
                   <a-button :style="{ marginRight: '8px' }" @click="()=>{
                     this.reviseShow = false;
                     // 清空弹窗数据
                     this.reviseForm.content = ''
                     this.reviseForm.name = ''
                   }">
                     取消
                   </a-button>
                   <a-button :style="{ marginRight: '8px' }" type="primary" @click="reviseSubmit">
                     确认
                   </a-button>
          </div>
               </a-modal>
            </div>
          </template>
        </a-drawer>

        <!-- 安排的弹窗 -->
        <a-modal v-model="meetShow" title="安排" @ok="handleSubmit2" @cancel="handleCancel3">
          <a-form-model ref="tryForm" :rules="tryRules"  :model="tryForm"  :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">

            <a-form-model-item label="类型"  prop="type" >
              <a-select v-model="tryForm.type" placeholder="请选择" @change="type5Change">
                <a-select-option value="">
                  请选择
                </a-select-option>
                <a-select-option value="需求">
                  需求
                </a-select-option>
                <a-select-option value="优化">
                  优化
                </a-select-option>
                <a-select-option value="缺陷">
                  缺陷
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item  label="计划完成日期" prop="time" style="">
              <a-date-picker
                  v-model="tryForm.time"
                  style="width: 100%"
                  format="YYYY-MM-DD"
                  :disabledDate="disabledDate"
              />
            </a-form-model-item>
          </a-form-model>


        </a-modal>

        <!-- 解决方案的弹窗 -->
        <a-modal v-model="resolvedShow" title="解决方案" @cancel="handleCancel2">
          <a-form-model :model="resolvedform" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="resolvedRules" ref="resolvedform">
            <a-form-model-item label="处理人 " prop="name">
              <a-input v-model="resolvedform.name" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item  label="处理日期" prop="time">
            <!-- <a-date-picker
            format="YYYY-MM-DD"
            v-model="resolvedform.time"
            >
              <template slot="dateRender" slot-scope="current, today">
                <div class="ant-calendar-date" >
                  {{ current.date() }}
                </div>
              </template>
            </a-date-picker> -->
              <a-date-picker
                  v-model="resolvedform.time"
                  style="width: 100%"
                  format="YYYY-MM-DD"
              />
            </a-form-model-item>
            <a-form-model-item  label="方案描述"   prop="content" >
              <a-textarea v-model="resolvedform.content" auto-size placeholder="请输入方案描述" style="min-height: 150px;font-size: 16px"/>
            </a-form-model-item>
          </a-form-model>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="()=>{
              this.resolvedShow = false; 
              this.resolvedform.content = ''
              this.resolvedform.name = ''  
              this.$refs.resolvedform.resetFields()
            }">
              取消
            </a-button>
            <a-button :style="{ marginRight: '8px' }" type="primary" @click="resolvedSubmit">
              确认
            </a-button>
          </div>
        </a-modal>
      </div>

    </div>
    <!-- table框的分页 -->
    <div style="margin-top: 28px;display:flex;align-items:center;justify-content:space-between">
      <span
     style="font-size: medium;color: #929292;margin-left: 3%;font-size: 14px;  bottom: 3%;">
     共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} / {{ Math.ceil(pagination.total /
       pagination.pageSize)
     }} 页
      </span>
      <a-pagination style="float: right;" show-quick-jumper show-size-changer :page-size.sync="pagination.pageSize"
      :total=pagination.total v-model="pagination.current"  @change="pageChange" @showSizeChange="pageChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getFeedback, getContentDetails, PostFeedback, postArrange, patchResolved, getResolved, patchUnesolved, patchReConfirm,
  getScheme,PostChangeFeedback,patchModifyScheme,delFeekBack,patchResolvedScheme,patchClose,patchReset,patchPause
} from '../../service/NewFeedback'

// 图片变链接
import { update } from "../../utils/update";

import moment from "moment";

import {
  get_signature,get_videoLink
} from "../../service/MedicalConference_y";

const columns = [
  { title: '类型',width: 100, dataIndex: 'type', key: 'name', scopedSlots: { customRender: 'titles' } },
  { title: '描述',width: 200, dataIndex: 'content', key: 'age',scopedSlots: { customRender: 'details' }  },
  { title: '所属模块', dataIndex: 'module', key: '1' },
  { title: '所属终端', dataIndex: 'channel', key: '2' },
  { title: '紧急程度', dataIndex: 'degree', key: '3' ,scopedSlots: { customRender: 'degree' }},
  { title: '创建时间', dataIndex: 'createdTime', key: '4' ,width:200},
  { title: '问题状态', dataIndex: 'status', key: '5' },
  { title: '创建人', dataIndex: 'name', key: '6' },
  { title: '计划完成日期', dataIndex: 'plannedDate', key: '7' },
  { title: '处理人', dataIndex: 'solver', key: '8' },
  { title: '处理日期', dataIndex: 'processDate', key: '9' },
  { title: '确认人', dataIndex: 'confirmer', key: '10' },
  { title: '确认时间', dataIndex: 'confirmDate', key: '11',width:200},
  {
    title: '操作',
    key: 'operation',
    fixed: 'right',
    width: 250,
    scopedSlots: { customRender: 'action' },
  },
];

const columns1 = [
  { title: '方案描述', dataIndex: 'solution', key: 'name',},
  { title: '解决人', dataIndex: 'solver', key: 'age'  },
  { title: '提交时间', dataIndex: 'updatedTime', key: '1' },
  {
    title: '操作',
    key: 'operation',
    // fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'action' },
  },
];

const treeData = [
  {
    title: '仅看自己负责',
    key: JSON.parse(localStorage.getItem('user_info')).id,
  },
];


export default {
  data() {
    let validateRange = (rule, value, callback) => { // 自定义校验规则-类型
      if (value) {
        callback();
      }else {
        callback(new Error('请选择类型'));
      }
    };
    return {
      val:'',
      expandedKeys: ['0-0-0', '0-0-1'],
      autoExpandParent: true,
      checkedKeys: ['0-0-0'],
      selectedKeys: [],
      treeData,
      stateTypeList: [  //用于紧急程度选择框
        {
          title: '全部',
          state: ''
        }, 
        {
          title: '紧急',
          state: '紧急'
        }, {
          title: '不紧急',
          state: '不紧急'
        }
      ],
      state2TypeList: [  //用于存放终端选择框
         {
          title: '全部',
          state: ''
        }, 
        {
          title: 'APP',
          state: 'APP'
        }, {
          title: '管理后台',
          state: '管理后台'
        },{
          title: '官网',
          state: '官网'
        },{
          title: '小程序',
          state: '小程序'
        },{
          title: '公众号',
          state: '公众号'
        },{
          title: '电脑客户端',
          state: '电脑客户端'
        }
      ], 
      state3TypeList: [  //用于存放终端选择框
         {
          title: '全部',
          state: ''
        }, 
        {
          title: '已解决',
          state: '已解决'
        }, {
          title: '未解决',
          state: '未解决'
        },{
          title: '待安排',
          state: '待安排'
        },{
          title: '已安排',
          state: '已安排'
        }
      ], 
      Ftype: '',
      IMAGES: [], //用于存图片
      VIEDOS: [], //用于存视频
      type: "",  //用于存放getFeedback的参数
      degree: "", //用于存放getFeedback的参数
      channel: "", //用于存放getFeedback的参数
      status: "", //用于存放getFeedback的参数
      pagination: {   //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      searchName: '', //问题描述框的内容
      columns,
      columns1,
      tableData: [],
      detailTableData:[],
      id: '',
      addVisible: false, //用于抽屉状态
      contentData: '',
      labelCol: { span: 4},
      wrapperCol: { span: 18 },
      anpailabelCol:{span:4},
      anpaiwrapperCol: { span: 18 },
      form: {  //表单的内容
        content: '',
        remark:'',
        module: undefined,
        channel: undefined,
        degree: undefined,
        images: [{ img: null }],
        videos: [{ videoo: null }], //视频文件
        // fileId: this.yunVideo_fileId,  //
      },
      resolvedform: {  //表单的内容
        content: '',
        name: '',
        time:'',
        // fileId: this.yunVideo_fileId,  //
      },
      resolvedformTime: "",
      reviseForm: {   //修跳改的弹窗
        content: '',
        name:'',
      },
      testform: {
        type:''
      },
      tryForm: {
        type: '',
        time:''    
      },
      
      arrangeForm: {  //表单的内容
        // content: '',
        // remark:'',
        // module: undefined,
        // channel: undefined,
        type: undefined,
        plannedDate: '',
        // images: [{ img: null }],
        // videos: [{ videoo: null }], //视频文件
        // fileId: this.yunVideo_fileId,  //
      },
      newlyVisible: false,
      rules: {
        content: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        degree: [{ required: true, message: '请选择紧急程度', trigger: 'blur' }],
        module: [{ required: true, message: '请输入模块', trigger: 'blur' }],
        channel: [{ required: true, message: '请选择终端', trigger: 'blur' }],
        // upImg: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        // viedo:[{ required: true, message: '请上传视频', trigger: 'blur' }],
        // province: [{required: true, message: '请选择省份/城市', trigger: 'change'}],
      },
      arrangeRules: {
        time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        type: [{ required: true, message: '请选择时间', trigger: 'blur' }],
      },
      tryRules: {
        time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        // type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
      },
      resolvedRules: {
        content: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        name: [{ required: true, message: '请输入处理人', trigger: 'blur' }],
        time: [{ required: true, message: '请选择处理日期', trigger: 'blur' }],
      },
      reviseFormRules: {
        content: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        name: [{ required: true, message: '请输入处理人', trigger: 'blur' }],
        time: [{ required: true, message: '请选择处理日期', trigger: 'blur' }],
      },
      index: '',  //图片索引
      VideoIndex:'',  //视频索引
      vFile: '',   //用于存储视频文件？
      signature: '',  //签名，我也不知道上传视频为什么用到签名\
      vUploader: {}, // 腾讯云上传的初始化对象
      yunVideo_fileId: '',
      disabledVideo: false,   //上传状态
      meetShow: false,  //用于安排弹窗
      resolvedShow: false,  //用于已解决弹窗
      reviseShow:false, //用于解决方案修改的弹窗
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
      },
      config: {
        rules: [{ type: 'object', required: true, message: 'Please select time!' }],
      },
      rangeConfig: {
        rules: [{ type: 'array', required: true, message: 'Please select time!' }],
      },
      arrangeId: '', //用于安排弹窗的id传参
      // plannedDate: '',  //用于安排传时间参数
      arrangeType: '',  //用于安排传类型参数
      resolvedId: '',   //用于存储已解决的id参数
      resolvedIdscheme:'',
      revisedId:'',   
      solver: '',  //已解决人姓名
      solution: '',  //已解决人解决方案
      reviseSolver: '',  //修改解决方案姓名
      reviseSolution: '',  //修改解决方案
      reviseTime:'',
      totalType: true,  //操作切换的状态
      buttonType: undefined,  //用与新建和修改按钮的切换
      changeId: '' , //修改的id
      anpaiTime:'',
      startTime :'', //用于获取当前时间给新建的菜单
    };
  },
    watch: {
    checkedKeys(val) {
      console.log('onCheck哈哈哈', val);
        this.val = val;
        this.getFeedback();
    },
  },
  created(){
    this.getFeedback();
    this.getAll();
    this.getdone();
    this.getComfirmed();
    this.testform = this.$form.createForm(this, { name: 'time_related_controls' });
  },
  methods: {
    // 暂不处理意见反馈
    async NotHandledFeekBack(id){
      const response = await patchPause(id)
      if (response.code === 0) {  
        this.$message.success('操作成功')
        this.getFeedback()
      } else {
        this.$message.warning(response.message)
      }
    },
    
    // 重启意见反馈
    async resetFeekBack(id){
      const response = await patchReset(id)
      if (response.code === 0) {  
        this.$message.success('操作成功')
        this.getFeedback()
      } else {
        this.$message.warning(response.message)
      }
      // console.log(id)
    },

    // 关闭意见反馈
    async closeFeekBack(id){
      const response = await patchClose(id)
      if (response.code === 0) {
        this.$message.success('操作成功')
        this.getFeedback()
      } else {
        this.$message.warning(response.message)
      }
      console.log(id)
    },

    disabledDate (current) {
      return current && current < moment().subtract(1, 'days').endOf('day')
    },
    // 解决方案修改的弹窗的取消事件
    handleCancel(e) {
      this.reviseShow = false;
      // 清空弹窗数据
      this.reviseForm.content = ''
      this.reviseForm.name = ''
      
    },
    // 已解决弹窗取消事件
    handleCancel2() {
      this.resolvedShow = false
      // 清空弹窗数据
      this.resolvedform.content = ''
      this.resolvedform.name = ''
      this.$refs.resolvedform.resetFields()
      
    },
    // 安排弹窗取消事件
    handleCancel3() {
      this.meetShow=false
      // 重置安排弹窗
      this.arrangeForm.type = ''
      this.arrangeForm.plannedDate = ''
      this.tryForm.type = ''
      this.tryForm.time = ''
      this.$refs.tryForm.resetFields()
    },
    onSubmit() {
      console.log('submit!', this.form);
    },
    // 抽屉状态
    afterVisibleChange(val) {
      // console.log('visible', val);
    },
    // 描述详情抽屉关闭
    onClose() {
      this.addVisible = false;
      

      // this.commentDetailParameter.startTime = ''
      // this.commentDetailParameter.endTime = ''
      // this.createValue = []
      // this.pagexxxSize = 10
    },
    // 新建抽屉关闭
    onClose1() {
      this.newlyVisible = false;
      this.disabledVideo = false
      // let form = this.form
      this.form.content = ''
      this.form.remark = ''
      this.form.module = undefined
      this.form.channel = undefined
      this.form.degree = undefined
      this.form.images = [],
        this.form.videos = []
      // 清空表单验证
      this.$refs.form.resetFields()
      // console.log("关闭时候的form",this.form)
    },

    async deleteFeekBack(id) {
      const response = await delFeekBack(id)
      if (response.code === 0) {
        this.$message.success('删除成功')
        // 刷新首页数据
        this.getFeedback()
        this.getdone() 
        this.getComfirmed()
      } else {
        this.$message.warning(response.message)
      }
      console.log(id)
    },

    // 1.1需求/缺陷类型切换框传参
    searchOk(e) {
      switch (e.target.value) {
        // 全部
        case '':
          // this.getFeedback(1,'METTING',this.searchType)
          this.type = ""
          this.pagination.pageSize = 10
          this.pagination.pageNum = 1
          this.pagination.current = 1
          this.getFeedback()
          break;
        // 缺陷
        case '缺陷':
          // this.getFeedback(1,'METTING',this.searchType)
          this.type = "缺陷"
          this.pagination.pageSize = 10
          this.pagination.pageNum = 1
          this.pagination.current = 1
          this.getFeedback()
          break;
        // 需求
        case '需求':
          // this.getLogData(1,'CONAULTATION',this.searchType)
          this.type = "需求"
          this.pagination.pageSize = 10
          this.pagination.pageNum = 1
          this.pagination.current = 1
          this.getFeedback()
          break;
        // 优化
        case '优化':
          // this.getLogData(1,'',this.searchType)
          this.type = "优化"
          this.pagination.pageSize = 10
          this.pagination.pageNum = 1
          this.pagination.current = 1
          this.getFeedback()
        break;
      }
    },
    // 1.2向getFeedback传紧急程度的参数（vod...）
    typeChange(value) {
      this.degree = value
      // // 重置页码和分页数 以及current
      this.pagination.pageSize = 10
      this.pagination.pageNum = 1
      this.pagination.current = 1

      // // console.log(this.stateType)
      this.getFeedback()

      console.log(value)
    },
    // 1.3向getFeedback传终端的参数（vod...）
    type2Change(value) {
      this.channel = value
      // // 重置页码和分页数 以及current
      this.pagination.pageSize = 10
      this.pagination.pageNum = 1
      this.pagination.current = 1

      // // console.log(this.stateType)
      this.getFeedback()
    },
    // 1.4向getFeedback传问题状态的参数（vod...）
    type3Change(value) {
      // 重置页码和current
      this.pagination.pageSize = 10
      this.pagination.pageNum = 1
      this.pagination.current = 1
      this.status = value
      this.getFeedback()
    },
    // 1.5向getFeedback传页码和行数的参数
    pageChange(page_no, page_size) {
      // console.log(page_size_options)
      this.pagination.pageNum = page_no
      this.pagination.pageSize = page_size
      // console.log(page_no)
      // console.log(page_size)
      
      this.getFeedback()
    },
    // 1.6 向getFeedback 传问题描述框的参数（文字）
    search() {
      // 点击查询的时候不要管页码的参数
      this.pagination.pageNum = 1;
      this.pagination.pageSize = 10;
      this.pagination.current = 1
      // console.log(this.searchName)

      this.getFeedback()
    },
    //1.getFeedback //获取意见反馈列表
    async getFeedback(type,degree,channel,content,status,pageSize,pageNum,memberId) { 
      // var startTime = this.PstartTime; var endTime = this.PendTime; var name = this.Pname; var pageNum = this.PpageNum; var pageSize = this.PpageSize
      var type = this.type; var degree = this.degree; var channel = this.channel; var content = this.searchName; var status = this.status; var pageNum = this.pagination.pageNum; var pageSize = this.pagination.pageSize; 
      var memberId = this.val
      const response = await getFeedback(type,degree,channel,content,status,pageSize,pageNum,memberId)
      if (response.code === 0) {
        // console.log("在这里", response.data.list)
        this.tableData = response.data.list
        this.pagination.total = response.data.total
        // this.getAll();
      } else {
        this.$message.warning(response.message)
      }
    },

    async getAll(type,degree,channel,content,status,pageSize,pageNum,memberId) { 
      // var startTime = this.PstartTime; var endTime = this.PendTime; var name = this.Pname; var pageNum = this.PpageNum; var pageSize = this.PpageSize
      var type = ''; var degree = ''; var channel = ''; var content = ''; var status = ""; var pageNum = '' ; var pageSize =  '';var memberId = ""
      const response = await getFeedback(type,degree,channel,content,status,pageSize,pageNum,memberId)
      if (response.code === 0) {
        // console.log("已解决", response)    
        localStorage.setItem('all', response.data.total);
        this.$store.commit('all', response.data.total)
      } else {
        this.$message.warning(response.message)
      }
    },

    // 显示待安排的个数
    async getdone(type,degree,channel,content,status,pageSize,pageNum,memberId) { 
      var type = ''; var degree = ''; var channel = ''; var content = ''; var status = "待安排"; var pageNum = '' ; var pageSize =  ''; var memberId = ""
      const response = await getFeedback(type,degree,channel,content,status,pageSize,pageNum,memberId)
      if (response.code === 0) {
        localStorage.setItem('done', response.data.total);
        this.$store.commit('done', response.data.total)
        console.log(response.data.total) 
      } else {
        this.$message.warning(response.message)
      }
    },
    // 显示待安排的个数
    async getComfirmed(type,degree,channel,content,status,pageSize,pageNum,memberId) { 
      var type = ''; var degree = ''; var channel = ''; var content = ''; var status = "待确认"; var pageNum = '' ; var pageSize =  ''; var memberId = ""
      const response = await getFeedback(type,degree,channel,content,status,pageSize,pageNum,memberId)
      if (response.code === 0) {
        this.$store.commit('confirmed', response.data.total)
      } else {
        this.$message.warning(response.message)
      }
    },

    // 获取描述详情
    async contentDetails(item) {
      this.addVisible = true;
      this.id = item.id  
      const response = await getContentDetails(this.id)
      if (response.code === 0) {
        this.contentData = response.data
        this.IMAGES = this.contentData.images 
        this.VIEDOS = this.contentData.videos 
        // this.detailTableData = response.data
        // console.log("数据",this.contentData)
      } else {
        this.$message.warning(response.message)
      }
      const responses = await getResolved(this.id)
      if (responses.code === 0) {
        // console.log("在这里", responses)
        this.detailTableData = responses.data
      } else {
        this.$message.warning(response.message)
      }
    },

    //1.getResolved //获取解决方案列表
    async getResolved(id) { 
      var id = this.id
      const response = await getResolved(id)
      if (response.code === 0) {
        // console.log("在这里", response)
        this.detailTableData = response.data
      } else {
        this.$message.warning(response.message)
      }
    },

    // 新建表单
    newlyBuilt() {
      this.newlyVisible = true;
      this.getIndex(this.index)
      this.getVideoIndex(this.VideoIndex)
      this.buttonType = true
      this.startTime = (moment().format('YYYY-MM-DD'))
      // console.log("看看是不是当前时间",startTime)
    },
    // 新建后的提交按钮
    async submit() {
      this.$refs.form.validate(async valid => { // 表单校验

        if (valid) {
          // 1.获取表单内容
          const form = this.form
          const user_info = window.localStorage.getItem("user_info");
          const addData = {
            // memberId:JSON.parse(user_info).id,
            remark: form.remark,     //备注
            content: form.content,   //描述
            degree: form.degree,    //紧急
            channel: form.channel,   //终端
            images: form.images,      //图片
            videos: form.videos,    //视频
            module: form.module
          }
          // console.log("有没有",addData)
          const response = await PostFeedback(addData)
          if (response.code === 0) {
            this.$message.success('新增成功')
            this.getFeedback()
            this.getAll();
            this.getdone()
            this.getComfirmed()
            this.newlyVisible = false;
            // 数据加上去再重置啊无语死了，先把我数据删了重置表单
            this.form.content = ''
            this.form.remark = ''
            this.form.module = undefined
            this.form.channel = undefined
            this.form.degree = undefined
            this.form.images = [],     //存在不能连续上传一样图片的bug
            this.form.videos = []
            // console.log("看看form", this.form)
          }
        } else { 
           this.$message.error("请填写完整")
        }

        
       })

    },

    // 修改后的提交按钮
    async editData() {
      // 1.获取表单内容
      const form = this.form
      const addData = {
       id:this.changeId,
       remark:form.remark,     //备注
       content:form.content,   //描述
       degree:form.degree,    //紧急
       channel:form.channel,   //终端
       images:form.images,      //图片
       videos: form.videos,    //视频
        module: form.module
       
      }
      const response = await PostChangeFeedback(this.changeId,addData)
      if (response.code===0){
        this.$message.success('修改成功')
        this.getFeedback()
        this.newlyVisible = false;

        // 数据加上去再重置啊无语死了，先把我数据删了重置表单
        this.form.content = ''
        this.form.remark = ''
        this.form.module = undefined
        this.form.channel = undefined
        this.form.degree = undefined
        this.form.images = [],     //存在不能连续上传一样图片的bug
        this.form.videos = []
      }
    },

    //  点击修改
    async FeekBackChange(item) {
      this.changeId = item.id
      this.buttonType = false
      // console.log(item.id)
      // 先得让数据进来啊 这个是什么啊
      this.newlyVisible = true;
      this.getIndex(this.index)
      this.getVideoIndex(this.VideoIndex)
      // 查询数据然后绑定上去
      const response = await getContentDetails(item.id)
      if (response.code === 0) {

       // memberId:JSON.parse(user_info).id,
      //  remark:form.remark,     //备注
      //  content:form.content,   //描述
      //  degree:form.degree,    //紧急
      //  channel:form.channel,   //终端
      //  images:form.images,      //图片
      //  videos: form.videos,    //视频
      //  module:form.module

        // this.contentData = response.data
        this.form = JSON.parse(JSON.stringify(response.data))  //content和备注
        // this.form.module = response.data.module  //模块
        // this.form.channel = response.data.channel  //终端  √
        // this.form.degree = response.data.degree  // 紧急程度  √
        // this.form.images = response.data.images    //图片 √
        // this.form.videos = response.data.videos  //视频
      } else {
        this.$message.warning(response.message)
      }
    },

    // 提交新增的意见反馈
    async PostFeedback(content,degree,module,channel,images,videos,remark) { 
      var content = "测试的" ; var degree = "不紧急"; var module = "社区"; var channel = "app"; var images = "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/21fa1959-6649-48c6-9ee2-6d440d134241.png " ; var videos =  "http://1252497858.vod2.myqcloud.com/e1684b32vodgzp1252497858/a55ac00f5576678020292644971/rlrTogpaHAEA.mp4";var remark =  "试试";
      const response = await PostFeedback(content,degree,module,channel,images,videos,remark)
      if (response.code === 0) {
        console.log("wuwuwu")
        // this.tableData = response.data.list 
        // this.pagination.total = response.data.total
      } else {
        this.$message.warning(response.message)
      }
    },


    //上传图片
    async uploadImg(type) {
      var inputTest = document.querySelector('#inputTest')
      let inputDOM = this.$refs[type].files[0];
      console.log(this.$refs[type])
      if(inputDOM.size < 512000 ){
        const image = await update(inputDOM)
        if (image.code === 0 && this.form.images.length < 9 ){
          switch (type){
            case 'addImg':
              this.form.images.push(image.data.url)
              break;
          }
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('最多只能上传9张图片')
        }
      }
      else{
        this.$message.warning('仅上传500k内图片')
      }
      inputTest.value =''
    },
    // 获取图片index
    getIndex(index) {
      this.index = index
      this.form.images.splice(index,1)
    },
    // 获取视频index
    getVideoIndex(index) {
      this.VideoIndex = index
      this.form.videos.splice(this.VideoIndex,1)
    },
    //获取签名
    async content_surface_plot_getSignature() {
      this.disabledVideo = true
      const response = await get_signature()
      if (response.code === 0) {
        this.signature = response.data.signature;
        // console.log("看看签名",this.signature)
      }
    },
    // 上传视频
    async content_surface_plot_original_video(e) {
      if (e.currentTarget.files[0]) { // 选择了文件
        if (e.currentTarget.files[0].type === 'video/mp4') {
          this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息
          // console.log( "看看文件",this.vFile )   √
          await this.content_surface_plot_getSignature()
          await this.uploadVideoFile_toyun()
        } else {
          this.$message.warning('仅支持mp4格式的视频上传')
        }
      }
      else { // 取消选择文件
        this.vFile = {}
        this.vIsFileExist = false
      }
      document.getElementById('up_video_surface_plot').value = null;
    },
    // 上传腾讯云 
    uploadVideoFile_toyun() {
      this.disabledVideo = true
      let _this = this
      const getSignature = () => {
        return _this.signature;
      }
      const tcVod = new _this.$TcVod({
        getSignature: getSignature // 前面的获取上传签名的函数
      })
      console.log( tcVod)
      const mediaFile = this.vFile
      this.vUploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })
      this.vUploader.done().then(res => { // 上传完成回调  √
        //视频上传后获取到的视频fileId
        this.yunVideo_fileId = res.fileId
        console.log('上传完成回调',res.fileId)
        //获取视频url地址
        this.content_video_url(res.fileId)
        this.$message.success('视频文件上传成功')
      })
    },
    // 获取视频url地址
    async content_video_url(videoId) {
      this.disabledVideo = true
      const response = await get_videoLink(videoId)
      if (response.code === 0) {
        // this.form.video = response.data.mediaUrl
        this.form.videos.push(response.data.mediaUrl)
        let audio = new Audio(response.data.mediaUrl)
        let self = this
        audio.addEventListener("loadedmetadata",function (e){
          self.duration = parseInt(audio.duration)
        })
      }
      else {
        this.$message.warning(response.message)
      }
      this.disabledVideo = false
    },
    openVideoUrl(url){
      window.open(url)
    },

    //1. 传递时间参数（点击确定）
    handleSubmit2() {
      this.$refs.tryForm.validate(async valid => { // 表单校验
        if(valid){
        this.meetShow = false
          // console.log("重新写", this.tryForm.time.format('YYYY-MM-DD'))
          this.anpaiTime = this.tryForm.time.format('YYYY-MM-DD')
          this.postArrange()
          this.tryForm.type = ''
          this.tryForm.time = ''
          // 清空表单验证
          this.$refs.tryForm.resetFields()
        } else {
           this.$message.error("请填写完整")
        }
      })

    },

    // 2. 安排弹窗传类型参数
    type5Change(value) {
      this.tryForm.type = value
    },


    // 2.1 安排弹窗传id
    ArrangeBtn(item) {
      // console.log("看看item",item.status)
      this.meetShow = true
      this.arrangeId = item.id
    },
    // 2 安排弹窗接口
    async postArrange(id,type,plannedDate) { 
      // var startTime = this.PstartTime; var endTime = this.PendTime; var name = this.Pname; var pageNum = this.PpageNum; var pageSize = this.PpageSize
      var type = this.tryForm.type; var id = this.arrangeId; var plannedDate =this.anpaiTime; 
      const response = await postArrange(id,type,plannedDate)
      if (response.code === 0) {
        this.getFeedback()  //运行成功就刷新页面
        this.getdone();
        this.getComfirmed()
        // 重置安排弹窗
        this.arrangeForm.type = ''
        this.arrangeForm.plannedDate = ''
      } else {
        this.$message.warning(response.message)
      }
    },

    // 3.1 传递id  跳
    Resolved(item) {
      this.resolvedIdscheme = item.id
      this.resolvedShow = true
    },

    ResolvedStatus(item) {
      this.resolvedId = item.id
      this.patchResolved()
    },
    // 3.2 提交已解决窗口(传文字参数)
    resolvedSubmit() {
      this.$refs.resolvedform.validate(async valid => { // 表单校验
        if (valid) {  
          const form = this.resolvedform
          this.solver = form.name
          this.solution = form.content
          this.resolvedformTime = form.time.format('YYYY-MM-DD')
          // 清空弹窗数据
          this.resolvedform.content = ''
          this.resolvedform.name = ''
          this.resolvedform.time = ''
          this.resolvedShow = false
          this.patchResolvedScheme()
          
          // this.getAll()
          // this.getdone()
          // this.getFeedback("已解决")
        } else {
          this.$message.error("请填写完整")
        }

      })
    },
    // 3.已解决弹窗
    async patchResolved(id,solver,solution) { 
      // var startTime = this.PstartTime; var endTime = this.PendTime; var name = this.Pname; var pageNum = this.PpageNum; var pageSize = this.PpageSize
      var solver = "" ; var id = this.resolvedId ; var solution = ""; 
      const response = await patchResolved(id,solver,solution)
      if (response.code === 0) {
        this.getFeedback()
        this.getdone()
        this.getComfirmed()
      } else {
        this.$message.warning(response.message)
      }
    },

    
    async patchResolvedScheme(id,solver,solution,processDate) { 
      // var startTime = this.PstartTime; var endTime = this.PendTime; var name = this.Pname; var pageNum = this.PpageNum; var pageSize = this.PpageSize
      var solver = this.solver ; var id = this.resolvedIdscheme ; var solution = this.solution; var processDate = this.resolvedformTime
      const response = await patchResolvedScheme(id,solver,solution,processDate)
      if (response.code === 0) {
        this.getFeedback()
        this.getdone()
        this.getComfirmed()
      } else {
        this.$message.warning(response.message)
      }
    },
    
    // 未解决确认框
    async confirm(id) {
      const response = await patchUnesolved(id)
      if (response.code === 0) {
        this.getFeedback()
      } else {
        this.$message.warning(response.message)
      }
    },
    // 重新打开确认框
    async reConfirm(id) {
      const response = await patchReConfirm(id)
      if (response.code === 0) {
        this.getFeedback()
        this.getdone()
        this.getComfirmed()
      } else {
        this.$message.warning(response.message)
      }
    },

    //4.1 传解决方案的文字v参数 关闭弹窗
    async reviseSubmit() {
      this.$refs.reviseForm.validate(async valid => { // 表单校验
        if (valid) {
          const form = this.reviseForm
          this.reviseSolver = form.name
          this.reviseSolution = form.content
          this.reviseTime = form.time.format('YYYY-MM-DD')
          var solver = this.reviseSolver; var id = this.revisedId; var solution = this.reviseSolution;var processDate = this.reviseTime;
          const response = await patchModifyScheme(id, solver, solution,processDate)
          if (response.code === 0) {
            this.getResolved()
            this.$message.success('修改成功')
            // 清空弹窗数据
            this.reviseForm.content = ''
            this.reviseForm.name = ''
            this.reviseForm.time = ''
          } else {
            this.$message.warning(response.message)
          }

          // console.log("测试提交",form.name)

          this.reviseShow = false
        } else {
          this.$message.error("请填写完整")
        }

      })

    },

    // 4.2 点击解决方案的修改(开弹窗)
    async revise(item) {
      this.reviseShow = true
      // console.log("瞅瞅id", item.id)
      this.revisedId = item.id
    },

  }
}
</script>

<style >
.sift{
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 50px;
}

.upload_gutter{
    /* 需要撤回就删 */
  /* margin-left: 10px; */
  display: inline-block;
  /* display: flex; */
  /* width: 200px; */
}

.uploadImg{
  width: 100%;
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
  margin-right: 11px;

  /* 需要撤回就删 */
  float: left;   
  display: inline-block;
}

.uploadImgVideo{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
    float: left;  
    margin-right: 12px;
}

.imgBox{
    border-bottom: 1px solid #e8e8e8;
 
}

.imgBox   h4{
      font-weight: 500; 
    }

.upload_LiveBroadcast{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  text-align: center;
  display: inline-block;
  float: left;
  margin-right: 12px
}

.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  cursor: pointer;
}
</style>